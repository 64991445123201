'use strict'

import collapsibleBlock from 'assets/core/js/module/collapsibleBlock'
import readMoreText from 'assets/core/js/module/readMoreText'
import common from 'assets/themes/campings/js/page/common/index'
import help from 'assets/themes/campings/js/page/common/help'
import languageSelector from 'assets/core/js/module/languageSelector'
import stickyObserver from 'assets/core/js/module/stickyObserver'
import trustPilot from '@ui/ThirdParty/Trustpilot/component'
import tooltip from 'assets/themes/campings/js/module/tooltip'

import HeaderMenu from '@ui/Header/Menu/BS/component'

common.preInit()

document.addEventListener('DOMContentLoaded', function () {
  common.init()

  // temporary: disable legacy BS service worker
  if ('serviceWorker' in navigator && document.body.getAttribute('data-application-id')?.match(/site-bungalow-specials/)) {
    navigator.serviceWorker
      .getRegistrations()
      .then(async (registrations) => {
        for (const registration of registrations) {
          await registration.unregister()
        }
      })
      .catch(() => {})
  }

  HeaderMenu()

  collapsibleBlock()
  readMoreText()

  help()
  languageSelector()
  stickyObserver()
  trustPilot()

  tooltip()
})
